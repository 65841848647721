import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {ToastProvider} from 'react-toast-notifications';

import Loading from "./components/Loading";
import {useAuth0} from "./utils/react-auth0-spa";
import * as authActions from "./store/actions/auth";
import AppRoutes from "./routing/Routes";
import Dev_Ping from './components/Dev/Dev_Ping';
import ApiError from "./components/ApiError";
import NavBar from "./components/Page/NavBar/NavBar";

const App = props => {
    const { loading, idToken } = useAuth0();
    const {verified, blockedUser, email, sid} = useSelector(state => state.auth);
    const {apiError, apiUrl} = useSelector(state => state.main);
    const dispatch = useDispatch();
    const { logout } = useAuth0();

    useEffect(() => {
        if (idToken) {
            dispatch(authActions.verifyUser(idToken));
        }
    }, [idToken]);
    // console.log('[App]', loading);
    if (blockedUser) {
        return (
            <>
                <NavBar />
                <div className="container">
                    <div className="row">
                        <div className="col-4">
                            <div className="alert alert-danger m-4">Access denied - {sid}</div>
                            <input className="btn btn-primary" value="Reauthorization" onClick={logout} />
                        </div>
                    </div>
                </div>
            </>
        );
    }
    if (loading || (idToken && !verified)) {
        return <Loading force={1} showNavBar={true}/>;
    }
    if (apiError) {
        return <ApiError apiError={apiError} apiUrl={apiUrl} email={email}/>
    }
    console.log('[App] loaded, verified', apiError, apiUrl, loading);

    return (
        <ToastProvider placement="bottom-right">
            <AppRoutes />
            <Loading />
            {/*<Dev_Ping pingUrl={process.env.REACT_APP_API_PATH} description="Api via cloudflare" timeout={2000}/>*/}
            {/*<Dev_Ping pingUrl="https://pep.admission.com.au/api/manager" description="Api direct" timeout={2000}/>*/}
        </ToastProvider>
    );
}

export default App;