import React from 'react';
import {useDispatch, useSelector} from "react-redux";

import * as actionTypes from "../../../../store/actions/actionTypes";

const AdmissionList_Item_ManagerCustomUI_SendAM = ({admission, customUI}) => {
    const dispatch = useDispatch();
    const uiData = admission.manager_customUI[customUI.id];
    const {selectedHospital} = useSelector(state => state.auth);

    // console.log('[AdmissionList_Item_ManagerCustomUI_SendAM]', admission, customUI, uiData);

    const doSend = () => {
        dispatch({
            type: actionTypes.MODAL_ADMISSION_TIME_SHOW,
            admission,
            selectedMessageTemplate: customUI.admissionMessageTemplates[0],
            admissionMessageTemplates: customUI.admissionMessageTemplates,
            managerTitle: customUI.managerTitle,
            customUI,
        });
    }

    if (!uiData.sent) {
        return <_Btn_Send onClick={doSend} btntitle={customUI.sendBtnTitle ? customUI.sendBtnTitle : '-'} isSmallBtn={customUI.isSmallBtn}/>
    }

    // return <DevUiData data={uiData}/>

    switch (uiData.message.status) {
        default:
        case "STATUS_SEND":
            return <button type="button" className={`btn btn-gray10 lifehouse-button-orange ${customUI.isSmallBtn ? 'btn-sm' : 'btn-group-table-button'}`}
                    data-tip={`Time sent: ${uiData.message.send_at}`} currentitem="false" onClick={doSend}><span className="icon ico-letter"></span>
            </button>
        case "STATUS_DONE":
            return <button type="button" className={`btn btn-gray10 lifehouse-button-green ${customUI.isSmallBtn ? 'btn-sm' : 'btn-group-table-button'}`}
                    data-tip={`Patient viewed message at: ${uiData.message.opened_at}`} currentitem="false" onClick={doSend}>
                <span className="icon ico-checkmark"></span>
            </button>
        case "STATUS_CONFIRMED":
            return <button
                    className={`btn ${customUI.isSmallBtn ? 'btn-sm' : 'btn-group-table-button'} ${selectedHospital.patient_confirmMessages_managerStyle ? selectedHospital.patient_confirmMessages_managerStyle : 'btn-success'}`}
                    data-tip={`Patient confirmed message at ${uiData.message.confirmed_at}`}
                    onClick={doSend}
                >
                    <span className="icon ico-checkmark"></span>
                </button>
    }
}

function _Btn_Send(props) {
    return <button
        className={`btn btn-dark-gray send-reg-btn ${props.isSmallBtn ? 'btn-sm' : 'btn-group-table-button '}`}
        onClick={props.onClick}>{props.btntitle}</button>;
}

export default AdmissionList_Item_ManagerCustomUI_SendAM;