import React, {useState, useEffect} from "react";
import {useSelector, useDispatch} from "react-redux";
import useOnClickOutside from "use-onclickoutside";
import {useToasts} from "react-toast-notifications";

import * as actionTypes from "../../store/actions/actionTypes";
import {sendMessage} from "../../store/actions/admission";
import {parseAdmissionTemplateVars} from '../../utils/utils';
import Admission_ModalInfo from "../Block/Admission_ModalInfo";
import ModalAdmissionTime_ExtraField from "./ModalAdmissionTime_ExtraField";

const ModalAdmissionTime = () => {
    const [text, setText] = useState('');
    const [patientTitle, setPatientTitle] = useState('');
    const [smsNotification, setSmsNotification] = useState(false);
    const [smsText, setSmsText] = useState('');
    const {addToast} = useToasts();
    const {show, admission, admTime, selectedMessageTemplate, extraFields, admissionMessageTemplates, managerTitle, customUI} = useSelector(state => state.modal.admissionTime);

    let admissionTemplateVars = {vars: [...admission.template_vars.vars], values: [...admission.template_vars.values], extraFields: extraFields};
    const admTimeKey = admissionTemplateVars.vars.push('{{_admTime}}') - 1;
    admissionTemplateVars.values.push('');

    const p = (str) => parseAdmissionTemplateVars(admissionTemplateVars, str);

    useEffect(() => {
        // console.log('ModalAdmissionTime useEffect', extraFields);
        admissionTemplateVars.values[admTimeKey] = admTime;

        setPatientTitle(p(selectedMessageTemplate.patient_title));
        setSmsText(p(selectedMessageTemplate.sms_template));
        setSmsNotification(selectedMessageTemplate.sms_enable);
        setText(p(selectedMessageTemplate.message));
    }, [show, admTime, selectedMessageTemplate, extraFields]);

    const dispatch = useDispatch();
    const ref = React.useRef(null);
    useOnClickOutside(ref, () => hideModal());

    const hideModal = () => dispatch({type: actionTypes.MODAL_ADMISSION_TIME_HIDE});
    const doCustomize = () => dispatch({type: actionTypes.MODAL_ADMISSION_TIME_CUSTOMIZE});

    const doSendMessage = (event) => {
        // setSendClicked(true);
        dispatch(sendMessage(admission, addToast, patientTitle, text, smsNotification, smsText, {'_admTime': admTime, "_template_id": selectedMessageTemplate.id}, customUI));
        event.preventDefault();
        dispatch({type: actionTypes.MODAL_ADMISSION_TIME_HIDE});
    }

    const handleAdmTime = event => {
        dispatch({type: actionTypes.MODAL_ADMISSION_TIME_SET_ADM_TIME, admTime: event.target.value});
        event.preventDefault();
    }

    const selectTemplate = event => {
        dispatch({type: actionTypes.MODAL_ADMISSION_TIME_SELECT_TEMPLATE, selectedMessageTemplate: admissionMessageTemplates[event.target.value]});
        event.preventDefault();
    }

    const renderSmsPreview = () => {
        if (selectedMessageTemplate.sms_enable) {
            return <>
                        <hr />
                        <strong>SMS:</strong> {smsText}
                    </>
        }
        return null;
    }

    const selectedAtmTemplateKey = () => admissionMessageTemplates.findIndex(item => item.id === selectedMessageTemplate.id);

    const renderExtraFields = () => {
        return (
            <div className="form-group">
                <div className="row">
                    {selectedMessageTemplate.extraVariables.map(item => <ModalAdmissionTime_ExtraField item={item} key={item.variable}/>)}
                </div>
            </div>
        );
    }

    const renderModal = () => {
        if (show) {
            return (
                <div className="modal-dialog modal-content-large" role="document" ref={ref} autoFocus={false}>
                    <div className="modal-content modal-content-fit">
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                                onClick={hideModal}>
                            <span aria-hidden="true">×</span>
                        </button>
                        <div className="modal-body">
                            <h3>{managerTitle} for {admission.title} {admission.surname}, {admission.name}</h3>
                            <Admission_ModalInfo admission={admission} />
                            <form onSubmit={doSendMessage} className="person-info-form">
                                <div className="form-group">
                                    <div className="row">
                                        <div className="col-8">
                                            Select Template <br />
                                            <select className="custom-select" id="inputGroupSelect01" onChange={selectTemplate} value={selectedAtmTemplateKey()}>
                                                {admissionMessageTemplates.map((item, key) => <option key={key} value={key}>{item.title}</option>)}
                                            </select>
                                        </div>
                                        {selectedMessageTemplate.extraVariables_admissionTime &&
                                            <div className="col-4">
                                                Admission time <br/>
                                                <input name="admTime" className="form-control" onChange={handleAdmTime} value={admTime}/>
                                            </div>
                                        }
                                    </div>
                                </div>
                                {renderExtraFields()}
                                <div className="form-group">
                                    <div className="row">
                                        <div className="col-12 d-flex justify-content-end">
                                            <input type="button" value="Cancel" className="btn" onClick={hideModal}/>
                                            <input type="submit" value="Send via Portal" className="btn btn-primary" onClick={doSendMessage}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group mb-4" style={{backgroundColor: '#eeeeee', padding: '10px', maxHeight: "400px",overflowY: "auto"}}>
                                    <strong>Title:</strong> {patientTitle}
                                    <hr />
                                    <span dangerouslySetInnerHTML={{__html: text }} />
                                    {renderSmsPreview()}
                                </div>
                                <div className="form-group">
                                    <div className="row">
                                        <div className="col-12 d-flex justify-content-end">
                                            <input type="button" value="Сustomise message" className="btn btn-secondary" onClick={doCustomize}/>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            );
        }
        return null;
    }

    return (
        <>
            <div className={`modal fade ${show ? 'show' : ''}`} id="personModal" tabIndex="-1" role="dialog"
                 aria-labelledby="personModalLabel" aria-modal="true">
                {renderModal()}
            </div>
        </>
    );
}

export default ModalAdmissionTime;