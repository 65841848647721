import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import { createRoot } from 'react-dom/client';
import axios from 'axios';
import { ReduxRouter } from '@lagunovsky/redux-react-router'
import {Provider} from 'react-redux';
import * as Sentry from '@sentry/browser';

import App from './App';
import * as serviceWorker from './serviceWorker';
import { Auth0Provider } from "./utils/react-auth0-spa";
import configureStore, { history } from './store/configureStore';

import 'react-json-pretty/themes/monikai.css';
import "react-datepicker/dist/react-datepicker.css";
import './assets/css/bootstrap.css';
import './assets/css/main.css';
import './assets/css/custom.css';

axios.defaults.baseURL = process.env.REACT_APP_API_PATH;

if (process.env.NODE_ENV === 'production') {
    Sentry.init({dsn: "https://da76f35f407a4eeb8de5d5303bc92243@sentry.admission.com.au/4"});

    const LogRocket = require('logrocket');
    LogRocket.init(process.env.REACT_APP_LOGROCKET_APP_ID, {
        console: {
            shouldAggregateConsoleErrors: true,
        }
    });
    const setupLogRocketReact = require('logrocket-react');
    setupLogRocketReact(LogRocket);
}

const onRedirectCallback = appState => {
    history.push(
        appState && appState.targetUrl
            ? appState.targetUrl
            : window.location.pathname
    );
};

const store = configureStore();

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
    <Auth0Provider
        domain={process.env.REACT_APP_AUTH0_DOMAIN}
        client_id={process.env.REACT_APP_AUTH0_CLIENT_ID}
        redirect_uri={process.env.REACT_APP_AUTH0_REDIRECT}
        onRedirectCallback={onRedirectCallback}
    >
    <Provider store={store}>
        <ReduxRouter history={history}>
            <App />
        </ReduxRouter>
    </Provider>
</Auth0Provider>);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
