import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {values} from 'lodash';
import ManagerCustomUI_DownloadPdf from "./ManagerCustomUI_DownloadPdf";
import ManagerCustomUI_UploadPdf from "./ManagerCustomUI_UploadPdf";
import ManagerCustomUI_PreAdmission from "./ManagerCustomUI_PreAdmission";
import usePermissions from "../../../../utils/permissions";
import * as actionTypes from "../../../../store/actions/actionTypes";
import {sendRegMed, unlinkSend2Overload} from "../../../../store/actions/customUI";

const ManagerCustomUI_MoreDrop = ({admission, onHide}) => {
    const managerCustomUI_unified = useSelector(state => state.auth.selectedHospital.managerCustomUI_unified);

    if (!managerCustomUI_unified) return ;

    const renderAllPositions = data => data.map(renderCustomUi);

    const renderCustomUi = (customUi, key) => {
        switch (customUi.type) {
            case 'TYPE_ADMISSION_LINK_DOWNLOAD_PDF':
                return <ManagerCustomUI_DownloadPdf admission={admission} managerCustomUi={customUi} onHide={onHide}/>
            case 'TYPE_ADMISSION_LIST_UPLOAD_PDF':
                return <ManagerCustomUI_UploadPdf admission={admission} managerCustomUi={customUi} onHide={onHide}/>
            case 'TYPE_ADMISSION_LIST_PRE_ADMISSION_INTEGRATION':
                return <ManagerCustomUI_PreAdmission admission={admission} managerCustomUi={customUi} onHide={onHide}/>
            case 'TYPE_ADMISSION_LIST_SEND_REG_MED':
                return <_SendRegMed admission={admission} managerCustomUi={customUi} onHide={onHide} key={`reg-med-${key}`}/>
            case 'TYPE_ADMISSION_LIST_COLUMN_SEND2':
                return <_Send2 admission={admission} managerCustomUi={customUi} onHide={onHide} key={`send2-${customUi.id}`}/>
            case 'TYPE_ADMISSION_LIST_COMMENTS':
                return;
            default:
                console.error("Unknown CustomUI custom UI type", customUi.type);
                break;
        }
    }

    return values(managerCustomUI_unified.admission_list).map(renderAllPositions)
}

const _SendRegMed = ({admission, managerCustomUi, onHide}) => {
    const dispatch = useDispatch();

    const uploadPdf = () => {
        onHide();
        dispatch({type: actionTypes.MODAL_ADMISSION_UPLOAD_PDF_SHOW, admission: admission});
    }

    return (
        <a className="dropdown-item pointer" onClick={uploadPdf}>Upload Patient Reg & Med History</a>
    );
}

const _Send2 = ({admission, managerCustomUi, onHide}) => {
    const dispatch = useDispatch();
    const {permissions, hasPermission} = usePermissions();

    const status = admission.manager_customUI[managerCustomUi.id];

    const unlink = () => {
        onHide();
        dispatch({type: actionTypes.MODAL_CONFIRM_ADMISSION_ACTION_SHOW, admission: admission, actionLabel: `Unlink PDF for ${managerCustomUi.managerTitle}`, actionDispatchable: unlinkSend2Overload(admission, managerCustomUi)})
    }

    if (status && status.document && status.document.overloadedBy) {
        return (
            <a className="dropdown-item pointer" onClick={unlink}>Unlink PDF for {managerCustomUi.managerTitle}</a>
        );
    }

    console.log('[_Send2]', managerCustomUi, status);

    const uploadPdf = () => {
        onHide();
        dispatch({type: actionTypes.MODAL_CUSTOM_UI_UPLOAD_PDF_SHOW, admission, customUI: managerCustomUi, status});
    }

    return (
        <a className="dropdown-item pointer" onClick={uploadPdf}>Upload {managerCustomUi.managerTitle}</a>
    );
}

export default ManagerCustomUI_MoreDrop;