import React, { useEffect, useState } from 'react';
import { useDispatch,useSelector } from 'react-redux';
import Pusher from 'pusher-js';
import ReactTooltip from 'react-tooltip';
import { AdmissionList_Item_Memo } from './Item/AdmissionList_Item';
import AdmissionList_Tabs_Memo from './Tabs/AdmissionList_Tabs';
import useSortableData from '../../utils/sortableData';
import useFilteredData from '../../utils/subfilteredData';
import AdmissionList_Search from './Tabs/AdmissionList_Search';
import useAdmissionListNavigation from '../../utils/admissionListNavigation';
import FlashMessages from '../FlashMessages/FlashMessages';
import AdmissionListMessages from '../AdmissionListMessages';
import AdmissionList_Bulk from './AdmissionList_Bulk';
import AdmissionList_SubFilter from './SubFilter/AdmissionList_SubFilter';
import AdmissionList_Pager from './AdmissionList_Pager';
import AdmissionList_SortHeaders from './AdmissionList_SortHeaders';

// This code is a mess. From what I see - I may never clean up it :(
// If you are trying to sort it out - sorry for this code.
// Today I need to complete weekly change set amount. Again.
// Permanent deadlines, permanent context switching with tons of the minor tasks in completely different areas.
// now I am burned out and I need to restore.
//
// Be careful, do not repeat my mistakes.


const AdmissionList = () => {
    //const dispatch = useDispatch();
    const [tabData, setTabData] = useState(null);
    const { pushAdmissionsListUrl } = useAdmissionListNavigation();
    const {  admissions, sortColumn, sortMode, totalPages } = useSelector(state => state.admissionList);
    // console.log(admissions)
    const {hospitalFeatures, manager_container_extraClass} = useSelector(state => state.auth.selectedHospital)
    const state = useSelector(state => state);
    // console.log(state)
    const {wsChannel} = useSelector(state => state.auth.selectedHospital)
    // console.log({wsChannel})
    const [admissionsData, setAdmissionsData] = useState(admissions);
    // console.log(admissionsData)
    useEffect(() => {
        setAdmissionsData(admissions);
    }, [admissions]);
    const { filteredItems } = useFilteredData(admissionsData);
    const { items, requestSort, getClassNamesFor } = useSortableData(pushAdmissionsListUrl, filteredItems, { key: sortColumn, direction: sortMode });
    // console.log(items)
    const {idToken} = useSelector(state => state.auth)
    let pusherClient = null;
    useEffect(() => {
    if (hospitalFeatures && hospitalFeatures.live_sync) {
        if (!pusherClient || !pusherClient.connection.connected) {
            pusherClient = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
                cluster: process.env.REACT_APP_PUSHER_CLUSTER,
                wsHost: process.env.REACT_APP_PUSHER_HOST,
                wsPort: 6001,
                forceTLS: false,
                encrypted: true,
                disableStats: true,
                enabledTransports: ['ws'],
                authEndpoint: `${process.env.REACT_APP_API_PATH}/broadcasting/auth`,
                auth: {
                    headers: {
                        'Authorization': idToken
                    },
                },
            });
        }

        const channelName = wsChannel;
        const channel = pusherClient.subscribe(`${channelName}`);

        const eventHandler = (data) => {
            console.log('Event data received:', data);

            setAdmissionsData((prevAdmissions) => {
                const index = prevAdmissions.findIndex(admission => admission.id === data.id);
                if (index !== -1) {
                    const updatedAdmissions = [...prevAdmissions];
                    updatedAdmissions[index] = data;
                    return updatedAdmissions;
                } else {
                    console.log(`Admission ID ${data.id} does not exist in the current list. Discarding change.`);
                    return prevAdmissions;
                }
            });
        };

        const eventHandlerTab = (data) => {
            console.log('Event data received:', data);
            setTabData(data);
        };

        channel.bind('view', eventHandler);
        channel.bind('process', eventHandler);
        channel.bind('add', eventHandlerTab);
        channel.bind('send_message', eventHandler);
        channel.bind('payment_complete', eventHandler);
        channel.bind('add_comment', eventHandler);
    } else {
        console.log('Live sync is disabled or hospital features not available.');
        if (pusherClient) {
            pusherClient.disconnect();
        }
    }
}, [wsChannel]);

    useEffect(() => {
        const handleBeforeUnload = () => {
            if (pusherClient) {
                pusherClient.disconnect();
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
            if (pusherClient) {
                pusherClient.disconnect();
            }
        };
    }, []);


    useEffect(() => {
        ReactTooltip.rebuild();
    });

    const devIsPagerDisabled = (!totalPages) || (totalPages === 1) || (totalPages === '1');
    console.log('AdmissionList render', items.length);

    const renderRow = (admission, key) => <AdmissionList_Item_Memo key={key} admission={admission} />;

    return (
        <main className="main">
            <div className={`container ${manager_container_extraClass}`}>
                <div className="row justify-content-center">
                    <div className="col-12">
                        <FlashMessages />
                        <h1 className="h2">Admissions List</h1>
                        <AdmissionListMessages />
                        <div className="table-holder bg-white rounded shadow-sm border">
                            <AdmissionList_Tabs_Memo pusherData={tabData} />
                            {devIsPagerDisabled && <AdmissionList_SubFilter />}
                            <div className="tab-content">
                                <div className="tab-pane fade show active" id="tab1" role="tabpanel"
                                     aria-labelledby="tab1">
                                    <AdmissionList_Search />
                                    <table className="table procedure-list-table" cellSpacing="0" width="100%">
                                        <AdmissionList_SortHeaders sortingEnabled={devIsPagerDisabled} _requestSort={requestSort} _getClassNamesFor={getClassNamesFor}/>
                                        <tbody>
                                        {items.map(renderRow)}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <AdmissionList_Bulk />
                    <AdmissionList_Pager />
                </div>
            </div>
        </main>
    );
}
AdmissionList.whyDidYouRender = true;

export default AdmissionList;