import React, {useState} from "react";
import {useSelector, useDispatch} from "react-redux";
import useOnClickOutside from "use-onclickoutside";

import * as actionTypes from "../../store/actions/actionTypes";
import {archiveAdmissionDocuments, resendSms} from "../../store/actions/admission";
import {useToasts} from "react-toast-notifications";
import UrDt from "../CustomLogic/UrDt";
import AdmDt from "../CustomLogic/AdmDt";

const ModalConfirmAdmissionAction = () => {
    const {show, admission, actionLabel, actionDispatchable} = useSelector(state => state.modal.confirmAdmissionAction);
    const {devSmsTarget_enable, devSmsTarget_phone} = useSelector(state => state.auth);

    const dispatch = useDispatch();
    const ref = React.useRef(null);
    useOnClickOutside(ref, () => hideModal());

    const hideModal = () => dispatch({type: actionTypes.MODAL_CONFIRM_ADMISSION_ACTION_HIDE});

    const doAction = () => {
        dispatch({type: actionTypes.RELOADING_PROGRESS_START, id: admission.id});
        dispatch(actionDispatchable);
        hideModal();
    }

    const showTargetPhone = () => {
        if (devSmsTarget_enable) {
            return (
                <dd><strike>{admission.mobilePhone}</strike> <span className="badge badge-danger">DEV TARGET ENABLED = {devSmsTarget_phone}</span></dd>
            );
        } else {
            return (
                <dd>{admission.mobilePhone}</dd>
            );
        }
    }

    const renderModal = () => {
        return (
            <div className="modal-dialog" role="document" ref={ref}>
            <div className="modal-content modal-content-fit">
                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={hideModal}>
                    <span aria-hidden="true">×</span>
                </button>
                <div className="modal-body">
                    <h3>{actionLabel} for {admission.title} {admission.surname}, {admission.name}</h3>
                    <dl className="person-list mb-3">
                        <UrDt admission={admission}/>
                        <AdmDt admission={admission}/>
                        <dt>Mobile</dt>
                        {showTargetPhone()}
                    </dl>
                    <form action="#" className="person-info-form">
                        <div className="form-group mb-4">
                            This change isn't reversible
                        </div>
                        <div className="form-group">
                            <div className="row">
                                <div className="col-12 d-flex justify-content-end">
                                    <input type="button" value={actionLabel} className="btn btn-primary" onClick={doAction}/>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        );
    }

    return (
        <>
            <div className={`modal fade ${show ? 'show' : ''}`} id="personModal" tabIndex="-1" role="dialog"
                 aria-labelledby="personModalLabel" aria-modal="true">
                {show && renderModal()}
            </div>
        </>
    );
}

export default ModalConfirmAdmissionAction;