import React from 'react';

import AdmissionList_SubFilter_Item from "./AdmissionList_SubFilter_Item";

const AdmissionList_SubFilter_Wards = ({items, handle}) => {
    if (!items) {
        return null;
    }
    return (
        <ul className="table-buttons-holder dropdown flex-break">
            {items.map((item, key) => <AdmissionList_SubFilter_Item key={key} label={item.code} value={item.code} handle={handle}/>)}
        </ul>
    )
}

export default AdmissionList_SubFilter_Wards;