import React, {Fragment} from 'react';
import {useSelector} from "react-redux";

import AdmissionList_Bulk_SelectAll from "./AdmissionList_Bulk_SelectAll";
import {CUSTOM_LOGIC_DEMO, CUSTOM_LOGIC_MARYVALE, useCustomLogic} from "../../utils/customLogic";
import AdmissionList_Header_ManagerCustomUI from "./Item/ManagerCustomUi/AdmissionList_Header_ManagerCustomUI";

const AdmissionList_SortHeaders = ({_requestSort, _getClassNamesFor}) => {
    const {isCustomLogicHealtheCare,isCustomLogicLifehouse, isCustomLogicESPH, isCustomLogicDemo, isCustomLogicMaryvale,  isCustomLogicFSPH, isCustomLogicJMPH} = useCustomLogic();
    const {selectedHospital} = useSelector(state => state.auth);
    // REFACTOR - use advancedList instead
    const {admissions, totalPages} = useSelector(state => state.admissionList);

    const sortingEnabled= (!totalPages) || (totalPages === 1)  || (totalPages === "1");

    const requestSort = (_param, defaultAsc) => {
        if (sortingEnabled) {
            return _requestSort(_param, defaultAsc)
        }
        return null;
    }
    const getClassNamesFor = _param => {
        if (sortingEnabled) {
            return _getClassNamesFor(_param)
        }
        return "no-sort";
    }

    const renderOec = () => {
        // TODO: refactor selectedHospital
        if (admissions.length && admissions[0].hospital.oec_enableManagerUI) {
            return <th className="no-sort" key="oec"><strong className="title">OEC Run</strong></th>
        }
        return null;
    }

    const renderAdmTime = () => {
        if (selectedHospital.atm_enable) {
            return <th className="no-sort ta-center" key="admtime"><strong className="title">Adm MSG</strong></th>
        }
        return null;
    }
    const renderManagerCustomUI = () => {
        // TODO: ACA-295 -  REFACTOR
        //       if (selectedHospital.managerCustomUI.length) {
        //          to
        //       useManagerCustomUI() + if(hasAdmissionListColumnSend())
        //     something similar to utiles/subfilteredData.js useFilteredData()
        if (selectedHospital.managerCustomUI.length) {
            return selectedHospital.managerCustomUI.map((item, key) => <th className="no-sort ta-center" key={`c${key}`}><strong
                className="title">{item.columnTitle}</strong></th>);
        }
    }


    const renderAdmDocs = () => {
        if (selectedHospital.admDocs_enable) {
            return <th key="admdocs" className="no-sort ta-center"><strong className="title">Docs</strong></th>
        }
        return null;
    }

    const renderLifeHousePanel = () => {
        if (selectedHospital.lifehouse_admissionListPanel_enable) {
            return (
                <Fragment key="lifehouse">
                    {customUIHeader(30)}
                    <th key="lh1" className={getClassNamesFor('erfaDate')} onClick={() => requestSort('erfaDate')} rowSpan="1" colSpan="1"><strong className="title">{isCustomLogicLifehouse() ? 'eRFA' : 'RFA'}</strong></th>
                    {customUIHeader(31)}
                    {selectedHospital.lifehouse_admissionComments_enable && <th key="lh2" className={getClassNamesFor('lifehouse_comments_status_weight')} onClick={() => requestSort('lifehouse_comments_status_weight')} rowSpan="1" colSpan="1"><strong className="title">Com.</strong></th>}
                    {customUIHeader(32)}
                    {selectedHospital.lifehouse_selectClinicalTeam_enable && <th key="lh3" className={getClassNamesFor('lifehouse_pac2')} onClick={() => requestSort('lifehouse_pac2')} rowSpan="1" colSpan="1"><strong className="title">REV</strong></th>}
                    {customUIHeader(33)}
                    <th key="lh4" className={getClassNamesFor('lifehouseFlags_pac')} onClick={() => requestSort('lifehouseFlags_pac')} rowSpan="1" colSpan="1"><strong className="title">PAC</strong></th>
                    {customUIHeader(34)}
                    <th key="lh5" className={getClassNamesFor('lifehouseFlags_reg')} onClick={() => requestSort('lifehouseFlags_reg')} rowSpan="1" colSpan="1"><strong className="title">REG</strong></th>
                    <th key="lh6" className={getClassNamesFor('clinicalFlags_summaryRating_sortable')} onClick={() => requestSort('clinicalFlags_summaryRating_sortable')} rowSpan="1" colSpan="1"><strong className="title">HIST</strong></th>
                    {customUIHeader(35)}
                    {isCustomLogicLifehouse() && <th key="lh7" className={getClassNamesFor('lifehouseFlags_book')} onClick={() => requestSort('lifehouseFlags_book')} rowSpan="1" colSpan="1"><strong className="title">BOOK</strong></th>}
                    {customUIHeader(36)}
                    {isCustomLogicLifehouse() && <th key="lh8" className={getClassNamesFor('lifehouseFlags_preg')} onClick={() => requestSort('lifehouseFlags_preg')} rowSpan="1" colSpan="1"><strong className="title">PREG</strong></th>}
                    {customUIHeader(37)}
                    <th key="lh9" className={getClassNamesFor('lifehouseFlags_adm')} onClick={() => requestSort('lifehouseFlags_adm')} rowSpan="1" colSpan="1"><strong className="title">Adm</strong></th>
                    {customUIHeader(38)}
                    {selectedHospital.lifehouse_admissionComments2_enable && <th key="lh10" className={getClassNamesFor('lifehouse_comments2_status_weight')} onClick={() => requestSort('lifehouse_comments2_status_weight')} rowSpan="1" colSpan="1"><strong className="title">IFC</strong></th>}
                </Fragment>
            );
        }
        return null;
    }
    const customUIHeader = pos => <AdmissionList_Header_ManagerCustomUI key={`custom${pos}`} position={pos} getClassNamesFor={getClassNamesFor} requestSort={requestSort}/>

    switch (selectedHospital.hospitalConfig.customLogic)
    {
        case "CUSTOM_LOGIC_PRIVATE_PRACTICE":
            return (
                <thead>
                <tr>
                    <th className="no-sort"><AdmissionList_Bulk_SelectAll /></th>
                    <th className={getClassNamesFor('surname')} onClick={() => requestSort('surname')}><strong className="title">Name(dob)</strong></th>
                    <th className={getClassNamesFor('admittingDoctor')} onClick={() => requestSort('admittingDoctor')}><strong className="title">Dr</strong></th>
                    <th className={getClassNamesFor('admissionTime')} onClick={() => requestSort('admissionTime')}><strong className="title">Time</strong></th>
                    <th className={getClassNamesFor('insuranceShortString')} onClick={() => requestSort('insuranceShortString')}><strong className="title">Type</strong></th>

                    <th className="no-sort">Messages</th>
                    <th className="no-sort">Form</th>
                    <th className="no-sort">RFA</th>
                    <th className="no-sort">Other</th>
                    <th className="no-sort" />
                </tr>
                </thead>
            );
        case "CUSTOM_LOGIC_LIFEHOUSE":
        case CUSTOM_LOGIC_DEMO:
        case "CUSTOM_LOGIC_ESPH":
        case "CUSTOM_LOGIC_FSPH":
        case "CUSTOM_LOGIC_JMPH":
        case "CUSTOM_LOGIC_HEALTHECARE":
        case "CUSTOM_LOGIC_MARYVALE":
        default:
            return (
                <thead>
                <tr>
                    <th key="1" className="no-sort"><AdmissionList_Bulk_SelectAll /></th>
                    {customUIHeader(1)}
                    <th key="2" className={getClassNamesFor('surname')} onClick={() => requestSort('surname')}><strong className="title">Patient</strong></th>
                    {customUIHeader(2)}
                    {(isCustomLogicMaryvale() || isCustomLogicHealtheCare() || isCustomLogicESPH() || isCustomLogicDemo() || isCustomLogicFSPH() || isCustomLogicJMPH()) && <th key="3" className={getClassNamesFor('admittingDoctor')} onClick={() => requestSort('admittingDoctor')}><strong className="title">Surgeon</strong></th>}
                    {customUIHeader(3)}
                    <th key="4" className={getClassNamesFor('admissionTime')} onClick={() => requestSort('admissionTime')}><strong className="title">Adm. Time</strong></th>
                    {customUIHeader(4)}
                    {(isCustomLogicMaryvale() || isCustomLogicHealtheCare() || isCustomLogicESPH() || isCustomLogicDemo() || isCustomLogicFSPH() || isCustomLogicJMPH()) && <th key="5" className={getClassNamesFor('insuranceShortString')} onClick={() => requestSort('insuranceShortString')}><strong className="title">Funding</strong></th>}
                    {renderLifeHousePanel()}
                    {customUIHeader(5)}
                    {renderOec()}
                    {customUIHeader(6)}
                    <th key="6" className="no-sort" />
                    {customUIHeader(7)}
                    {renderManagerCustomUI()}
                    {renderAdmTime()}
                    {customUIHeader(8)}
                    {renderAdmDocs()}
                    {customUIHeader(9)}
                    <th key="7" className="no-sort" />
                </tr>
                </thead>
            );
    }

}

export default AdmissionList_SortHeaders;